.wallet {
    h2.title {
        font-size: 30px;
        font-weight: 700;
        color: #008800;
        border-bottom: 1px solid #19CD00;
        text-align: center;
        padding: 5px 0;
    }
    h3.sub-title {
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        margin-top: 20px;
    }
}