.skysenx {
  .account-info {
    box-sizing: border-box;
    border-radius: 12px;
    padding: 7px 0;
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:first-child {
      height: 50px;
      width: 50px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      background: #B2B7C2;
      img {
        width: 25px;
      }
    }
    > div:last-child {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      h4 {
        color: #181b36;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: #55576b;
      }
    }
  }
  
}