.app {
  background-color: #fff;
  padding-bottom: 60px;
}
.toast {
  position: absolute;
  bottom: 70px;
  left: 10px;
  right: 10px;
  background-color: #181b36;
  opacity: 0;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.135px;
  padding: 15px;
  border-radius: 8px;
  backdrop-filter: blur(1px);
  animation-name: toast;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 300ms;
  animation-fill-mode: forwards;
}
@keyframes toast {
0% {
  opacity: 0;
}
100% {
  opacity: .9;
}
}
@media (min-width: 700px) {
  body {
    background-color: var(--bg-dark);
    .app {
      max-width: 450px;
      > .shade {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 450px;
        background-color: #fff;
      }
    }
  }
}
