.marketplace {
  .cart {
    .item {
      display: flex;
      flex-direction: row;
      border-bottom: 1px solid #979797;
      padding: 14px 0;
      align-items: flex-start;
      img {
        width: 80px;
        margin-right: 10px;
      }
      strong {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #181b36;
      }
      > div {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        h4 {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #181b36;
        }
        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.4px;
          color: #55576b;
        }
        .controls {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            max-width: 200px;
            
          .quantity-modifier {
            width: 100px;
            margin-top: 8px;
          }
          > span {
            font-weight: 400;
            font-size: 10px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #fd8a20;
            cursor: pointer;
          }
        }
      }
    }
  }
}
