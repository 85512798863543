.meter-info {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 7px 12px;
  cursor: pointer;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  > div:first-child {
    height: 40px;
    width: 40px;
    background: #f0e299;
    border-radius: 40px;
    display: flex;
    img {
      width: 100%;
    }
  }
  > div:last-child {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    h4 {
      color: #181b36;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #55576b;
    }
  }
}
