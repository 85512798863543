.airtime {
  .order {
    padding: 0 20px;
    .order-types {
      line-height: 40px;
      max-width: 300px;
      margin: 0 auto;
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      box-sizing: border-box;
      margin-top: 14px;
      background: #e2e4e8;
      span {
        border-radius: 20px;
        flex-grow: 1;
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.25px;
        color: #55576B;
        cursor: pointer;
        &.active {
          background-color: #19cd00;
          color: #fff;
        }
      }
    }
  }
}
