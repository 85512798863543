.plan-chooser {
  background-color: #fff;
  width: 80%;
  border-radius: 12px;
  margin: 0 auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  > h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #181b36;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px 12px 0 0;
    > span {
      margin-left: 10px;
    }
    > img {
      cursor: pointer;
    }
  }

  .plan-types {
    line-height: 40px;
    max-width: 300px;
    margin: 0 auto;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-top: 14px;
    background: #e2e4e8;
    span {
      border-radius: 20px;
      flex-grow: 1;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 40px;
      letter-spacing: 0.25px;
      color: #55576B;
      cursor: pointer;
      &.active {
        background-color: #19cd00;
        color: #fff;
      }
    }
  }
  > .plans {
    align-items: center;
    display: grid;
    grid-gap: 8px;
    max-height: 600px;
    padding: 20px;
    overflow: scroll;
    &.monthly {
      grid-template-columns: repeat(3, 1fr);
    }
    &.weekly {
      grid-template-columns: repeat(2, 1fr);
    }
    .plan-select {
      text-align: center;
      background-color: #fff;
      line-height: 30px;
      border: 0.3px solid #eeeeee;
      box-shadow: 0px 1.81875px 8px rgba(226, 228, 232, 0.41);
      border-radius: 9.09375px;
      padding: 12px 5px;
      cursor: pointer;
      h5 {
        font-size: 10.9125px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.36375px;
        color: #181b36;
        font-weight: 400;
      }
      strong {
        font-weight: 500;
        font-size: 18.1875px;
        line-height: 22px;
        letter-spacing: 0.136406px;
        color: #19cd00;
      }
      p {
        font-weight: 400;
        font-size: 9.09375px;
        line-height: 18px;
        letter-spacing: 0.227344px;
        color: #b2b7c2;
      }
      &.current {
        background: #b8fa99;
        p {
          color: #181b36;
        }
      }
    }
  }
}
