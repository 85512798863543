.marketplace {
  .deliverables {
    .deliverable-items {
      .varieties {
        margin-top: 7px;
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #181b36;
        }
        .list {
          display: flex;
          flex-direction: row;
          margin-top: 2px;
          justify-content: left;
          .variety {
            border: 0;
            background: #e2e4e8;
            border-radius: 50px;
            font-weight: 500;
            font-size: 12px;
            padding: 0 10px;
            min-width: 50px;
            line-height: 27px;
            letter-spacing: 0.15px;
            text-align: center;
            color: #7b8293;
            cursor: pointer;
            margin-left: 10px;
            text-transform: uppercase;
            &.active {
              background: #19cd00;
              color: #000000;
            }
          }
          .variety:first-child {
            margin: 0;
          }
        }
      }
    }
  }
}
