.marketplace {
  .deliverables {
    .brand {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.4px;
        color: #55576b;
      }
      h2 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: #181b36;
      }
      img {
        margin-top: 20px;
        width: 229px;
        height: 154px;
      }
    }
  }
}
