input::placeholder {
  font-weight: 400;
  line-height: 24x;
  color: #b2b7c2;
  font-size: 14px;
  opacity: 1;
}
form {
  :-moz-placeholder,
  :-ms-input-placeholder,
  ::-moz-placeholder,
  ::-webkit-input-placeholder {
    font-weight: 400;
    line-height: 24x;
    color: #b2b7c2;
    font-size: 14px;
  }
  .row {
    margin-top: 10px;
  }
  .form-input {
    margin-bottom: 15px;
  }
  label {
    display: block;
    color: #55576b;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }
  input,
  select,
  textarea {
    background: #fff;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    padding: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.1px;
    width: 100%;
    color: #55576b;
    margin-top: 4px;
    box-sizing: border-box;

    &.error {
      border: 1px solid #c02;
    }
  }
  input:focus,
  select:focus,
  textarea:focus {
    outline: none;
  }
  span.error {
    color: red;
    line-height: 25px;
    font-size: 15px;
  }
  input[disabled] {
    background-color: #e2e4e8;
  }
}
@media (min-width: 700px) {
  form {
    .fl-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .col-50 {
        flex-basis: 48%;
      }
    }
  }
}
