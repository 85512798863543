.settings {
  .menu {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .list {
      padding: 0 20px;
      .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #181b36;
        letter-spacing: 0.15px;
        margin-top: 20px;

        p {
          flex-grow: 1;
          line-height: 38px;
          color: #555555;
          font-size: 16px;
          font-weight: 300;
        }
      }
      .item:last-child {
        border: 0;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      padding-top: 40px;
      > h4 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #181b36;
        margin-top: 11px;
      }
      > p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.25px;
        color: #181b36;
      }
    }
  }
}
