.addresses {
    padding: 0 20px;
  > label {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.1px;
    color: #55576b;
  }
}
