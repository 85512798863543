.decoder-bouquet {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #55576b;
      strong {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #181b36;
      }
    }
  }
}
