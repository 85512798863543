.renew-cycle-chooser {
  background-color: #fff;
  width: 80%;
  border-radius: 12px;
  margin: 0 auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  > h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #181b36;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px 12px 0 0;
    > span {
      margin-left: 10px;
    }
    > img {
      cursor: pointer;
    }
  }

  > .renew-cycles {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    max-height: 440px;
    overflow: scroll;
    .renew-cycle-select {
      padding: 12px 0;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #e2e4e8;
      margin-top: 7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
        margin-left: 10px;
        flex-grow: 1;
      }
      strong {
        color: #19cd00;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .renew-cycle-select:last-child {
      border-bottom: 0;
    }
  }
}
