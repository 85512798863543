.settings {
  .delete-account {
    padding: 0 20px;
    > h4 {
      font-weight: 400;
      font-size: 14px;
      line-height: 50px;
      letter-spacing: 0.25px;
      color: #181b36;
      text-align: center;
      margin-top: 40px;
    }
    > button {
      background-color: red;
    }
  }
}
