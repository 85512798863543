.home {
  
  header {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: baseline;
    img {
      width: 35px;
      height: 35px;
    }
    p {
      color: #181b36;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
  }
  section {
    background: #fff;
    border-radius: 20px 20px 0px 0px;
    padding: 0 20px 0 20px;
    h4 {
      font-size: 12px;
      font-weight: 500;
      line-height: 24px;
      color: #333333;
      letter-spacing: 0.15px;
      margin-top: 10px;
    }

    .services {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        .service {
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          cursor: pointer;
        }
        .service:first-child {
          margin: 0;
        }
        &.x4 {
          .service {
            width: 25%;
            img {
              height: 100%;
            }
          }
        }
        &.x3 {
          .service {
            background-color: #EFF2FF;
            border-radius: 8px;
            width: 33.3%;
          }
        }
        &.x2 {
          .service {
            width: 50%;
            height: auto;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
