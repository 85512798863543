.electricity {
  .order {
    padding: 0 20px;
    margin: 0 auto;
    
    button.renew {
      border: 0;
      background-color: var(--bg-dark);
      box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
      color: var(--text-light);
      display: block;
      width: 100%;
      margin: 20px auto;
      line-height: 40px;
      border-radius: 5px;
      font-size: 16px;
      text-transform: uppercase;
    }
    p.processing-time {
      text-align: center;
      font-size: 13px;
      font-weight: 500;
      color: #c02;
    }
    .triggers {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        display: block;
        width: 100%;
        background-color: #fff;
        margin: 20px 0;
        font-size: 16px;
        cursor: pointer;
        color: #fc7900;
        border: 0;
        padding: 0;
      }
    }
    .charges {
      color: #c02;
    }
    form p {
      color: #55576b;
    }
  }
}
