section.auth {
    background: #fff;
    > div .container {
        margin: 0 auto;
        box-sizing: border-box;
        padding: 15px;
    }
    header {
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        margin-left: -15px;
        img {
            width: 133px;
        }
    }
    footer {
        position: fixed;
        bottom: 0;
        margin: 10px auto;
        left: 0;
        width: 100%;
        p {
            line-height: 40px;
            font-size: 12px;
            text-align: center;
            color: #181B36;
        }
    }
}
@media (min-width: 700px) {
    body {
        background-color: var(--bg-dark);
        section.auth {
            max-width: 450px;
            > .shade {
                position: absolute;
                z-index: -1;
                top: 0;
                left: 0;
                bottom: 0;
                width: 100%;
                max-width: 450px;
                background-color: #fff;
            }
        }
    }
}
