.welcome {
  .slide {
    display: none;
    flex-direction: column;
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-moz-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-o-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @-ms-keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    &.active {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    img {
      overflow: hidden;
    }
    .info {
      padding: 30px;
      padding-bottom: 0;
      box-sizing: border-box;
      h3 {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
        text-align: center;
        letter-spacing: 0.15px;
      }
      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.25px;
        color: #b2b7c2;
      }
    }
  }
}
