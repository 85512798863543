.app {
  > .menu {
    background-color: #fff;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 12px;
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      margin: 0;
      li {
        display: block;
        width: 25%;
        position: relative;
        img {
          width: 20px;
          margin-top: 5px;
        }
        a {
          display: flex;
          flex-direction: column;
          text-align: center;
          text-decoration: none;
          font-size: 10px;
          line-height: 16px;
          color: #B2B7C2;
          align-items: center;
          -webkit-tap-highlight-color: transparent;
        }
      }
      li.active a {
        color: #19CD00;
      }
    }
    .slider {
      display: flex;
      flex-direction: row;
      strong {
        transition: width 200ms ease-in-out;
      }
      span {
        width: 25%;
        i {
          width: 60px;
          display: block;
          margin: 0 auto;
          height: 5px;
          background-color: #32c832;
        }
      }
    }
  }
}
@media (min-width: 700px) {
  .app {
    > .menu {
      max-width: 450px;
    }
  }
}
