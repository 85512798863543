div.checkbox {
  input {
    display: none;
  }

  div {
    line-height: 30px;
    display: flex;
    flex-direction: row;
    text-align: right;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.4px;
    color: #181b36;
    align-items: center;

    span {
      background: #E2E4E8;
      height: 20px;
      width: 36px;
      box-sizing: border-box;
      border-radius: 18px;
      cursor: pointer;
      position: relative;
      margin-left: 8px;

      &.checked {
        background: #19CD00;
      }

      &.checked i {
        left: 18px;
      }

      i {
        top: 2.5px;
        background: #fff;
        left: 5px;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        display: block;
        position: absolute;
        transition: left 200ms ease-in-out;
      }
    }
  }
}
