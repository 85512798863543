.static {
  .privacy {
    padding: 0 20px;
    .wrapper {
      position: relative;
      top: 60px;
      left: 20px;
      right: 20px;
      width: 100%;
      bottom: 70px;
      position: absolute;
      
      iframe {
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
  }
}
