.app-create-dedicated-nuban {
    h5.title {
        color: #fc7900;
        font-weight: 400;
        font-size: 18px;
        line-height: 40px;
        text-align: center;
    }
    > p {
        font-size: 10px;
        color: #606060;
        margin-top: 10px;
        text-align: center;
    }
}
