.airtime {
  .network-provider {
    background-color: #eff2ff;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 12px;
    min-height: 64px;
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:first-child {
      height: 40px;
      width: 40px;
      border-radius: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      &.mtn {
        background: #ffcc00;
        img {
          width: 33px;
        }
      }
      &.airtel {
        background: #ee1c25;
        img {
          height: 22px;
        }
      }
      &.glo {
        background: linear-gradient(180deg, #13910b 0%, #0b3d05 100%);
        img {
          height: 22px;
        }
      }
      &._9mobile {
        background: #006d52;
        img {
          height: 25px;
        }
      }
    }
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #181b36;
      margin-left: 10px;
      flex-grow: 1;
    }
  }
}
