.order {
  .details {
    padding: 20px;
    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 {
        font-weight: 600;
        font-size: 24px;
        line-height: 42px;
        text-align: center;
        color: #000000;
      }
    }
    .summary {
      margin-top: 50px;
      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.4px;
          margin-bottom: 20px;
        }
        p:first-child {
          color: #b2b7c2;
        }
        p:last-child {
          color: #181b36;
          text-align: right;
        }
        p.status {
          color: #19cd00;
        }
      }
    }
    button.download {
      background: #19cd00;
      border: 0;
      line-height: 30px;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 300;
      border-radius: 5px;
      width: 170px;
      display: block;
      margin: 20px 0 0 auto;
      color: #fff;
      text-align: center;
      cursor: pointer;
    }
  }
}
