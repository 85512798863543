.marketplace {
    .deliverables {
      .deliverable-items {
        padding: 10px 20px 20px 20px;
        label {
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #55576b;
          margin-top: 20px;
          display: block;
        }
      }
    }
  }
  