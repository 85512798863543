.dialog {
    position: relative;
    .shade {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background-color: #999;
        opacity: .5;
        z-index: 1;
    }
    > .content {
        z-index: 2;
        position: fixed;
        display: flex;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
@media (min-width: 700px) {
    .dialog {
        position: relative;
        .shade, > .content {
            max-width: 450px;
        }
    }
}