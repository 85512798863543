section.pages {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    > div .container {
        margin: 0 auto;
        box-sizing: border-box;
        padding: 15px;
        header {
            margin-left: -15px;
        }
    }
    footer {
        position: fixed;
        bottom: 0;
        margin: 10px auto;
        left: 0;
        width: 100%;
        p {
            line-height: 40px;
            font-size: 12px;
            text-align: center;
            color: #181B36;
        }
    }
}
