.event-info {
  box-sizing: border-box;
  border-radius: 12px;
  padding: 7px 10px;
  cursor: pointer;
  margin-top: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > img {
    width: 45px;
  }
  > div {
    display: flex;
    flex-direction: column;
    h4 {
      color: #181b36;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.1px;
      color: #55576b;
    }
  }
}
