.welcome {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: #fff;
    max-width: 450px;

    .slides {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        overflow: hidden;
    }

    .triggers {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 250px;
        box-sizing: border-box;
        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            list-style-type: none;
            list-style-image: none;
            padding: 0;
            margin: 10px 0;
            max-width: 100px;
            li {
                height: 7px;
                width: 7px;
                border-radius: 3.5px;
                margin: 0 2px;
                background: #D0D0D0;
                &.active {
                    background: #19CD00;
                }
            }
        }
        button {
            text-transform: capitalize;
            font-size: 15px;
            line-height: 35px;
            border: none;
            color: var(--text-light);
            font-weight: 400;
            cursor: pointer;
            width: 100%;
            max-width: 220px;
            border-radius: 5px;
            background-color: var(--bg-dark);
            box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
        }
        button:focus {
            outline: none;
        }
    }
}
