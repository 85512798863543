.marketplace {
  .cart {
    padding: 10px 20px 20px 20px;
    button {
      &.continue {
        color: #181b36;
        border: 1px solid #181b36;
        background-color: #fff;
      }
    }
    .subtotal {
      display: flex;
      flex-direction: row;
      padding: 13px 0;
      p {
        flex-grow: 1;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
      strong {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
      }
    }
  }
}
