.provider-chooser {
  background-color: #fff;
  width: 80%;
  border-radius: 12px;
  margin: 0 auto;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  > h3 {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #181b36;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 12px 12px 0 0;
    > span {
      margin-left: 10px;
    }
    > img {
        cursor: pointer;
    }
  }

  .providers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 30px;
    .provider {
      padding: 12px;
      width: 100%;
      cursor: pointer;
      border-bottom: 1px solid #e2e4e8;
      margin-top: 7px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > div:first-child {
        height: 40px;
        width: 40px;
        border-radius: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.mtn {
          background: #ffcc00;
          img {
            width: 33px;
          }
        }
        &.airtel {
          background: #ee1c25;
          img {
            height: 22px;
          }
        }
        &.glo {
          background: linear-gradient(180deg, #13910b 0%, #0b3d05 100%);
          img {
            height: 22px;
          }
        }
        &._9mobile {
          background: #006d52;
          img {
            height: 25px;
          }
        }
      }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #000000;
        margin-left: 10px;
        flex-grow: 1;
      }
    }
    .provider:last-child {
      border-bottom: 0;
    }
  }
}
