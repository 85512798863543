.ticketing {
  .events {
    .records {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      .searchbox {
        margin-bottom: 14px;
      }
      .event {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px;
        background-color: #EFF2FF;
        margin-bottom: 20px;
        border-radius: 6px;
        cursor: pointer;
        > div {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
            letter-spacing: 0.135px;
            color: #181b36;
          }
          h6 {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #55576b;
          }
          p {
            margin-top: 7px;
            display: flex;
            flex-direction: row;
            align-items: center;
            i {
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              letter-spacing: 0.4px;
              color: #55576B;
            }
            span {
              padding-left: 10px;
              color: #19CD00;
            }
          }
        }
        > img {
          height: 90px;
          border-radius: 6px;
        }
      }
    }
  }
}
