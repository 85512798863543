.delete-confirmation {
  background-color: #fff;
  width: 80%;
  border-radius: 12px;
  margin: 0 auto;
  height: 250px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  position: relative;

  > img {
    position: absolute;
    right: -15px;
    top: -15px;
    cursor: pointer;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-top: 75px;
    }
    .triggers {
      margin-top: 30px;
      button {
        width: 80px;
        height: 30px;
        left: 33px;
        top: 158px;
        border-radius: 8px;
        border: 0;
        color: #FFFFFF;
        cursor: pointer;
      }
      button:first-child {
        background: #FF0000;
      }
      button:last-child {
        background: #181B36;
        margin-left: 20px;
      }
    }
  }
}
