.order {
  .orders {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .order {
      display: flex;
      flex-direction: row;
      background: #ffffff;
      border-bottom: 1px solid #ccc;
      align-items: center;
      cursor: pointer;
      
      > img:last-child {
        height: 20px;
        width: 20px;
      }
      > div.content {
        flex-grow: 1;
        padding: 20px 10px;
        > div {
          line-height: 25px;
          flex-grow: 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          strong {
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            &.status {
              font-weight: 400;
              color: #19cd00;
            }
          }
          p {
            font-size: 14px;
          }
        }
        > div:first-child p {
          font-size: 12px;
          color: #181B36;
        }
      }
    }
  }
}
