.decoders {
  .records {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .decoder {
      display: flex;
      flex-direction: row;
      align-items: center;
      > div:first-child {
        flex-grow: 1;
      }
      > img {
        width: 24px;
        height: 34px;
        margin-left: 20px;
        cursor: pointer;
      }
      .content {
        background-color: #eff2ff;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 15px;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        > div:first-child {
          height: 50px;
          width: 50px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
          flex-direction: row;
          &.dstv {
            background: #026EB6;
          }
          &.gotv {
            background: #F1F529;
          }
          &.startimes {
            background: #fff;
          }
          img {
            width: 45px;
          }
        }
        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          padding: 0 10px;
          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #181b36;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.4px;
            color: #55576b;
          }
        }
      }
    }
  }
}
