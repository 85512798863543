.marketplace {
  .quantity-modifier {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > div {
      background: #19cd00;
      border: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: 0.15px;
      text-align: center;
      color: #fff;
      height: 27px;
      width: 27px;
      border-radius: 4.05px;
      cursor: pointer;
    }

    span {
      height: 27px;
      width: 50px;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      letter-spacing: 0.15px;
    }
  }
}
