.accounts {
  .records {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-direction: column;
    .account {
      display: flex;
      flex-direction: row;
      align-items: center;
      > div:first-child {
        flex-grow: 1;
      }
      > img {
        width: 24px;
        height: 34px;
        margin-left: 20px;
        cursor: pointer;
      }
      .content {
        background-color: #eff2ff;
        box-sizing: border-box;
        border-radius: 10px;
        padding: 10px 15px 18px 15px;
        cursor: pointer;
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        > .details {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          > h4 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.4px;
            color: #181b36;
            span {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #55576b;
            }
          }
          > div:first-child {
            display: flex;
            flex-direction: column;
            h4 {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.4px;
              color: #181b36;
            }
            > .logo-cont {
              height: 50px;
              width: 50px;
              background: #fedbff;
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: 24px;
              }
            }
          }
          > .info {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            position: relative;
            h5 {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.4px;
              color: #55576b;
              margin-bottom: 3px;
            }
            strong {
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              color: #55576b;
              margin-bottom: 8px;
            }
            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.4px;
              color: #55576b;
            }
            span.status {
              text-align: right;
              position: absolute;
              bottom: -13px;
              right: 5px;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.4px;
              color: #17ba00;
              &.expired {
                color: #fd8a20;
              }
            }
          }
        }
      }
    }
  }
}
