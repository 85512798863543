.skysenx {
  .about {
    padding: 0 20px;
    text-align: center;
    h4 {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #55576B;
      margin-bottom: 10px;
    }
    p {
        margin-bottom: 5px;
    }
  }
}
