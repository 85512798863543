.address {
  .area-input {
    background-color: #fff;
    box-sizing: border-box;
    border: 1.5px solid #e2e4e8;
    border-radius: 8px;
    padding: 7px 5px;
    min-height: 54px;
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #181B36;
      margin-left: 10px;
      flex-grow: 1;
    }
  }
}
