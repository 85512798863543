.ticketing {
  .tags {
    .tag {
        margin-bottom: 5px;
      input {
        width: 100%;
        background-color: #fff;
        box-sizing: border-box;
        border: 1px solid #e2e4e8;
        border-radius: 5px;
        padding: 12px;
      }
    }
  }
}
