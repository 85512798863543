.wallet {
    .dashboard {
        padding: 0 20px;
        .wallet-info {
            margin: 20px 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 20px;
            justify-content: center;
            strong {
                font-weight: 400;
            }
            img {
                margin: 0 3px 0 10px;
            }
        }
        button.active {
            border: 0;
            background-color: var(--bg-dark);
            box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
            color: var(--text-light);
            display: block;
            width: 100%;
            margin: 20px auto;
            padding: 0 20px;
            line-height: 40px;
            border-radius: 5px;
            font-size: 20px;
            cursor: pointer;
        }
        button.cancel {
            border: 0;
            padding: 0;
            background-color: #fff;
            color: #FC7900;
            display: block;
            width: 100%;
            max-width: 150px;
            margin: 20px auto;
            line-height: 35px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
        }
    }
}