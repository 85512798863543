.marketplace {
  .deliverables {
    .deliverable-items {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .deliverable-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: #eff2ff;
        border-radius: 8px;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        > div {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          h4 {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #181b36;
          }
          p {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 0.4px;
            color: #55576b;
            margin-top: 5px;
          }
        }
        strong {
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #181b36;
        }
      }
    }
  }
}
