.utility {
  .distributor {
    background-color: #eff2ff;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div:first-child {
      height: 40px;
      width: 40px;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      img {
        width: 35px;
      }
    }
    h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #181b36;
      margin-left: 10px;
      flex-grow: 1;
    }
  }
}
