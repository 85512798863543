header.app {
  a.info {
    background: #693179;
    border-radius: 12px;
    height: 24px;
    width: 24px;
    position: absolute;
    right: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 16px;
    }
  }
}
