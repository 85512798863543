.skysenx {
  .renew-cycle {
    background-color: #fff;
    box-sizing: border-box;
    border: 1px solid #e2e4e8;
    border-radius: 8px;
    padding: 15px 12px;
    cursor: pointer;
    margin-top: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #55576b;
      flex-grow: 1;
      span {
        margin-left: 10px;
      }
    }
  }
}
