.payment-success {
  background: #fff;
  width: 90%;
  border-radius: 12px;
  height: 350px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.2));
  position: relative;
  > a {
    position: absolute;
    right: -15px;
    top: -15px;
  }
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      margin-top: 75px;
    }
    img {
        margin-top: 37px;
    }
  }
}
