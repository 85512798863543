.app {
  section.sub-header {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    align-items: baseline;
    p {
      color: #55576b;
      letter-spacing: 0.1px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
    button {
      border: 0;
      display: flex;
      flex-direction: row;
      background: #19cd00;
      border-radius: 50px;
      text-decoration: none;
      align-items: center;
      padding: 9px;
      cursor: pointer;
      span {
        color: #fff;
      }
      span:first-child {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-left: 5px;
      }
      span:last-child {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 30px;
        line-height: 16px;
        letter-spacing: 0.4px;
        margin-left: 8px;
      }
    }
  }
}
