.settings {
  .help {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      line-height: 35px;
      text-align: center;
      font-weight: 400;
      font-size: 18px;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #181b36;
      margin-top: 20px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #181b36;
      margin-top: 20px;
    }
    a {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #19cd00;
      letter-spacing: 0.15px;
      margin-top: 10px;
      text-decoration: none;
    }
    a:last-child {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
