.airtime {
  form {
    .fixed-amounts {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 7px;
      .fixed-amount {
        border: 0;
        background: #f6f7fa;
        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        width: 22%;
        font-weight: 500;
        font-size: 14px;
        line-height: 40px;
        letter-spacing: 0.25px;
        text-align: center;
        color: #55576B;
        cursor: pointer;
        &.active {
            background: #19CD00;
            color: #fff;
        }
      }
    }
  }
}
