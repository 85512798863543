.home {
    .slides {
        padding-top: 15px;
        .slide {
            cursor: pointer;
            padding-right: 7px;
            img {
                width: 100%;
            }
        }
    }
}