.update {
    .required {
        display: flex;
        flex-direction: row;
        justify-content: center;
        button {
            border: 0;
            background-color: var(--bg-dark);
            box-shadow: 0px 10px 30px rgba(0, 80, 133, 0.15);
            color: var(--text-light);
            display: block;
            min-width: 200px;
            margin: 50px auto;
            padding: 0 20px;
            line-height: 40px;
            border-radius: 5px;
            font-size: 20px;
            cursor: pointer;
        }
        p {
            color: #000;
            line-height: 30px;
            margin: 20px 0;
        }
    }
}
