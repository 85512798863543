header.app {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px;
    justify-content: center;
    align-items: center;
    align-items: baseline;
    position: relative;
    a.back {
        position: absolute;
        left: 27px;
        img {
            width: 10px;
            height: 17px;
        }
    }
    p {
        color: #181B36;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        letter-spacing: 0.15px;
    }
}
