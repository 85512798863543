.settings {
  .get-password {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    max-width: 300px;

    h4 {
      font-weight: 500;
      font-size: 16px;
      line-height: 50px;
      letter-spacing: 0.25px;
      color: #181b36;
      text-align: center;
    }

    .action {
      background-color: red;
      &.continue {
        background-color: white;
        border: 1px solid #181b36;
        color: #181b36;
      }
    }
  }
}
