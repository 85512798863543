header.app {
  a.cart-box {
    position: absolute;
    right: 27px;
    img {
      width: 24px;
    }
    label {
      right: -6px;
      top: -8px;
      position: absolute;
      font-weight: 500;
      font-size: 8px;
      color: #ffffff;
      background: #ee1c25;
      line-height: 16px;
      text-align: center;
      border-radius: 8px;
      height: 16px;
      width: 16px;
    }
  }
}
