div.searchbox {
  display: flex;
  flex-direction: row;
  background: #f6f7fa;
  border-radius: 38.9286px;
  align-items: center;
  padding: 10px 20px;

  img {
    margin-right: 10px;
  }
  
  input {
    background: transparent;
    border: 0;
    line-height: 30px;
    height: 30px;
    font-size: 24px;
    color: #55576B;
    flex-grow: 1;
  }

  input:focus {
    outline: none;
  }
}
