.cabletv {
  .create-order {
    padding: 20px;
    background-color: #fff;
    .summary {
      margin: 0 auto;
      border: 1px solid #e2e4e8;
      border-radius: 12px;
      .decoder-info {
        margin-left: 20px;
      }
      .info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 5px;
        margin: 12px 20px 0 20px;
        span {
          color: #55576b;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.4px;
        }
        span:last-child {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #181B36;
        }
      }
      .info:last-child {
        border-top: 1px solid #e2e4e8;
        padding: 10px 0;
      }
    }
    .wallet-info {
      margin: 20px auto;
      max-width: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 20px;
      justify-content: space-between;
      p {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #181b36;
      }
      > div {
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.1px;
          color: #149200;
        }
        img {
          margin-right: 2px;
          height: 13px;
        }
      }
    }
    h5.low-fund {
      color: #fc7900;
      font-weight: 500;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
    }
  }
}
