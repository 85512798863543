.home {
  .wallet {
    background: linear-gradient(180deg, #18c100 0%, #094a00 100%);
    border-radius: 12px;
    min-height: 100px;
    margin-top: 11px;
    display: flex;
    padding: 14px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    p {
        color: #fff;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
    }
    h2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        span {
            font-size: 24px;
            line-height: 24px;
            font-weight: 600;
            color: #fff;
            letter-spacing: 0.18px;
            margin-left: 3px;
        }
    }
  }
}
