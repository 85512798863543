.dialog {
    .alert {
        background: #fff;
        min-width: 300px;
        max-width: 80%;
        padding: 10px;
        box-sizing: border-box;
        border-radius: 10px;
        text-align: center;
        position: relative;
        h3 {
            font-weight: 400;
            color: red;
            margin-bottom: 5px;
        }
        p {
            word-wrap: break-word;
            margin-bottom: 10px;
            color: #000;
        }
        button {
            background-color: var(--bg-dark);
            color: var(--text-light);
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
            border: 0;
            margin-bottom: 10px;
        }
        img {
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
        }
    }
}