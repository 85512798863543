.marketplace {
  .detail {
    padding: 10px 20px 20px 20px;
    p.stats {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #ee1c25;
      margin-top: 10px;
    }
    .item-info {
      display: flex;
      flex-direction: column;
      > div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #181b36;
        }
        strong {
          font-weight: 500;
          font-size: 20px;
          line-height: 33px;
          letter-spacing: 0.203571px;
          color: #19cd00;
        }
      }
      h5 {
        font-weight: 500;
        font-size: 11px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #181b36;
      }
      p {
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: #55576b;
      }
    }
    > button {
      margin-top: 34px;
    }
  }
}
