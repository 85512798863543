:root {
  --bg-dark: #19cd00;
  --bg-darker: #4e73df;
  --text-light: #fff;
  --bg-main: #f2f6ff;
}

* {
  margin: 0;
}

body {
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  color: #333333;
}

h1,
h2,
h3,
h4 {
  font-family: "Poppins", sans-serif;
}
.btn {
  &.action {
    border: 0;
    cursor: pointer;
    color: #fff;
    width: 100%;
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 46px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    background: #19CD00;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    margin-top: 10px;
  }
}
