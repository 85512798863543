section.auth {
  color: #262626;
  h3 {
    font-size: 20px;
    margin: 20px 0 30px 0;
    text-align: center;
    color: #333333;
    font-weight: 500;
  }
  .login {
    padding-top: 100px;
  }
  .extras {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    a {
      color: #37ac83;
      text-decoration: none;
      font-size: 17px;
    }
  }
  .reset {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: -15px 0 20px 0;
    a {
      color: #b2b7c2;
      text-decoration: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
    }
  }
  .new-account {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.1px;
      color: #b2b7c2;
      a {
        color: #19cd00;
      }
    }
  }
  a.signup {
    color: #19cd00;
    text-decoration: none;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    padding-left: 6px;
  }
  p {
    color: #181B36;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
  }
  .more {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .terms {
    p {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #181B36;
      text-align: left;
    }
    a {
      font-weight: 300;
      font-size: 12px;
      line-height: 18px;
      color: #19cd00;
      text-decoration: none;
    }
  }
}
