.dedicated-nuban {
  h2 {
    color: #19cd00;
    font-weight: 400;
    font-size: 16px;
    margin: 15px 0;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      span {
        font-size: 16px;
        line-height: 24px;
        color: #aaaaaa;
        display: block;
      }
    }
  }
}
